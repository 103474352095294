<template>
 <nav class="relative flex flex-wrap items-center justify-between px-2 py-3 bg-indigo-500 mb-3 z-9999 ">
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
      <div class="w-full relative flex justify-between lg:w-auto  px-2 lg:static lg:block lg:justify-start">
        <a class="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white" href="/">
          <!-- Meniu CNPEE -->
        </a>
        <button class="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none" type="button" v-on:click="toggleNavbar()">
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <!--  <div v-bind:class="{'hidden': !showMenu, 'flex': showMenu}" class="lg:flex lg:flex-grow items-center">
        <ul class="flex flex-col lg:flex-row list-none ml-auto">
          <li class="nav-item">
            <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="#pablo">
              <i class="fab fa-facebook-square text-lg leading-lg text-white opacity-75" /><span class="ml-2">Share</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="#pablo">
              <i class="fab fa-twitter text-lg leading-lg text-white opacity-75" /><span class="ml-2">Tweet</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="#pablo">
              <i class="fab fa-pinterest text-lg leading-lg text-white opacity-75" /><span class="ml-2">Pin</span>
            </a>
          </li>
        </ul>
      </div> -->
    </div>
  </nav>
	<!-- <nav id="nav" class="container mx-auto">	
	
	<div
			class="z-10 max-w-screen-lg xl:max-w-screen-xl block sm:flex sm:justify-between sm:items-center my-6"
		>
			<div class="flex justify-between items-center">
				<div>
					<router-link to="/"
						><img
							v-if="theme === 'light'"
							
							src="../../assets/images/logo-cnpee3.svg"
							class="w-36"
							alt=""
						/>
					
						<img
							v-else
							src="../../assets/images/logo-light.svg"
							class="w-36"
							alt=""
						/>
					</router-link>
				</div>

				<theme-switcher
					:theme="theme"
					@themeChanged="updateTheme"
					class="block sm:hidden bg-ternary-light dark:bg-ternary-dark hover:bg-hover-light dark:hover:bg-hover-dark hover:shadow-sm px-2.5 py-2 rounded-lg ml-10"
				/>

				<div class="sm:hidden">
					<button
						@click="isOpen = !isOpen"
						type="buttom"
						class="focus:outline-none"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							class="h-6 w-6 fill-current text-secondary-dark dark:text-ternary-light"
						>
							<path
								v-if="isOpen"
								fill-rule="evenodd"
								d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
								clip-rule="evenodd"
							></path>
							<path
								v-if="!isOpen"
								fill-rule="evenodd"
								d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
							></path>
						</svg>
					</button>
				</div>
			</div>

			<div
				:class="isOpen ? 'block' : 'hidden'"
				class="ml-3 sm:ml-4 mt-5 sm:mt-3 sm:flex justify-center items-center"
			>
				<router-link
					to="/articole"
					class="block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light  md:mx-6 mb-2 sm:py-2"
					>Articole</router-link
				>
				<router-link
					to="/manuale"
					class="block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light  md:mx-6 mb-2 sm:py-2"
					>Manuale</router-link
				>
				<router-link
					to="/auxiliare"
					class="block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light  md:mx-6 mb-2 sm:py-2"
					>Auxiliare</router-link
				>
				<router-link
					to="/proiecte"
					class="block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light  md:mx-6 mb-2 sm:py-2"
					>Proiecte</router-link
				>

				<router-link
					to="/projects"
					class="block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light  md:mx-6 mb-2 sm:py-2"
					>Transparenta institutionala</router-link
				>
				<router-link
					to="/about"
					class="block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light  md:mx-2 mb-2 sm:py-2"
					>Despre CNPEE</router-link
				>
				<router-link
					to="/contact"
					class="block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light  md:mx-2 mb-2 sm:py-2"
					>Contact</router-link
				>
			</div>

	
			
			<div
				class="hidden sm:flex justify-between items-center flex-col md:flex-row"
			>
				<div
					class="ml-8 bg-primary-light dark:bg-ternary-dark px-3 py-2 shadow-sm rounded-xl cursor-pointer"
				> Schimba tema
					<theme-switcher
						:theme="theme"
						@themeChanged="updateTheme"
					/>
				</div>
			</div>
		</div>
	</nav> -->
        <nav>
        

        <label for="drop" class="toggle">Meniu CNPEE</label>
        <input type="checkbox" id="drop" />
            <ul class="menu">
                <!-- <li><a href="/">Acasa</a></li> -->
				<li><div>
					<router-link to="/"
						><img
							v-if="theme === 'light'"
							src="../../assets/images/logo-cnpee3.svg"
							class="w-36"
							alt="logo-cnpee3"
							loading="lazy"
						/>
						<img
							v-else
							src="../../assets/images/logo-light.svg"
							class="w-36"
							alt="logo-ligth"
							loading="lazy"
						/>
					</router-link>
				</div>
				</li>
				<li>
					<label for="drop-11" class="toggle">Despre</label>
					<a href="/about" target="_blank" rel="noopener noreferrer">Despre</a>
						<input type="checkbox" id="drop-11"/>
							<ul>
								<li><a href="/about" target="_blank" rel="noopener noreferrer">Despre</a></li>
								<li><a href="/arhiva" target="_blank" rel="noopener noreferrer">Arhiva site vechi</a></li>
                    		</ul>
				</li>
				
				<li>
                    <!-- First Tier Drop Down -->
                    <label for="drop-1" class="toggle">Directia Curriculum, Evaluari si Examene</label>
                    <a href="/dcee" target="_blank" rel="noopener noreferrer">Directia Curriculum, Evaluari si Examene</a>
                    <input type="checkbox" id="drop-1"/>
                    <ul>
						<li><a href="/examene" target="_blank" rel="noopener noreferrer">Examene și concursuri</a></li>
                        <li><a href="/en8" target="_blank" rel="noopener noreferrer">Evaluare Națională</a></li>
                        <li><a href="/definitivat" target="_blank" rel="noopener noreferrer">Definitivat</a></li>
                        <li><a href="/titularizare" target="_blank" rel="noopener noreferrer">Titularizare</a></li>
						<li><a href="/curriculum" target="_blank" rel="noopener noreferrer">Curriculum</a></li>
						<li><a href="/manualesiauxiliare" target="_blank" rel="noopener noreferrer">Manuale și auxiliare</a></li>
                    	<li><a href="/manuale" target="_blank" rel="noopener noreferrer">Manuale școlare</a></li>
                    	<li><a href="/auxiliare" target="_blank" rel="noopener noreferrer">Auxiliare didactice</a></li>
                    </ul> 
                </li>

				<li>
                    <!-- First Tier Drop Down -->
                    <label for="drop-2" class="toggle">Unitatea de Cercetare in Educatie</label>
                    <a href="https://www.ise.ro" target="_blank" rel="noopener noreferrer">Unitatea de Cercetare in Educatie</a>
                    <input type="checkbox" id="drop-2"/>
                    <ul>
						<li><a href="https://www.ise.ro/teme-de-cercetare" target="_blank" rel="noopener noreferrer">Cercetare</a></li>
                        <li><a href="https://www.ise.ro/parteneriate" target="_blank" rel="noopener noreferrer">Parteneriate</a></li>
                        <li><a href="https://www.ise.ro/formare" target="_blank" rel="noopener noreferrer">Formare</a></li>
                        <li><a href="https://www.ise.ro/ise-in-actualitate" target="_blank" rel="noopener noreferrer">UCE in actualitate</a></li>
						<li><a href="https://www.ise.ro/resurse" target="_blank" rel="noopener noreferrer">Resurse</a></li>
                    	<!-- <li><a href="/manuale">Manuale școlare</a></li>
                    	<li><a href="/auxiliare">Auxiliare didactice</a></li> -->
                    </ul> 
                </li>
				
                
				<!-- <li>
                    <label for="drop-1" class="toggle">Examene și concursuri</label>
                    <a href="/examene">Examene și concursuri</a>
                    <input type="checkbox" id="drop-1"/>
                    <ul>
						<li><a href="/examene">Examene și concursuri</a></li>
                        <li><a href="/en8">Evaluare Națională</a></li>
                        <li><a href="/definitivat">Definitivat</a></li>
                        <li><a href="/titularizare">Titularizare</a></li>
                    </ul> 

                </li>
                <li>
                <label for="drop-2" class="toggle">Manuale si auxiliare</label>
                <a href="/manualesiauxiliare">Manuale si auxiliare</a>
                <input type="checkbox" id="drop-2"/>
                <ul>
					<li><a href="/manualesiauxiliare">Manuale și auxiliare</a></li>
                    <li><a href="/manuale">Manuale școlare</a></li>
                    <li><a href="/auxiliare">Auxiliare didactice</a></li>
                    
                </ul>
                </li> -->

                <li>
					<!-- First Tier Drop Down -->
                <label for="drop-31" class="toggle">Programe</label>
                <a href="/programe">Programe</a>
                <input type="checkbox" id="drop-31"/>
                <ul>
					<li><a href="/programe">Programe</a></li>
                    <li><a href="/cpeecn">C.P.E.E.C.N.</a></li>
            		<li><a href="/ceem">C.E.E.M.</a></li>
                </ul>
                </li>
                
					<li><a href="/transparentainstitutionala">Transparenta institutionala</a></li>
                    <!-- 
					<li>
					<label for="drop-4" class="toggle">Transparenta institutionala</label>
                    <a href="/transparentainsitut">Transparenta institutionala</a>
                     <input type="checkbox" id="drop-4"/>
                    <ul>
						<li><a href="/anunturi">Anunturi</a></li>
						<li><a href="/concursuri">Concursuri</a></li>
						<li><a href="/audit">Audit</a></li>
						<li><a href="/scim">SCIM</a></li>
						<li>      
                    		<label for="drop-5" class="toggle">Date deschise</label>
                    			<a href="/datedeschise">Date deschise</a>
                    			<input z-index="9999" type="checkbox" id="drop-5"/>
                    			<ul>
		                 	        <li><a href="/transparenta">Transparenta</a></li>
									<li><a href="/legislatie">Legislatie</a></li>
									<li><a href="/platformeinfo">Platforme informatice</a></li>
									<li><a href="/datedeschise">Date deschise</a></li>
									<li><a href="/rapoarteper">Rapoarte periodice</a></li>
									<li><a href="/achizitiisilic">Acchizitii si licitatii</a></li>
									<li><a href="/transparentafin">Transparenta financiara</a></li>
									<li><a href="/venituriangajati">Venituri angajati</a></li>
									<li><a href="/declaratiiaveresiint">Declaratii de avere si interese</a></li>
									<li><a href="/legea544">Legea 544</a></li>
									<li><a href="/registruunic">Registru unic al transparentei intereselor</a></li>
									<li><a href="/transparentafin">Transparenta financiara</a></li>
									<li><a href="/audit">Audit</a></li>
									<li><a href="/programescolare">programescolare</a></li>
									<li><a href="/talis">talis</a></li>
                		    	</ul> 
                    	</li>
                    </ul> </li> -->
                <li><a href="/contact">Contact</a></li>
                
				<li>
				<div class="hidden sm:flex justify-between items-center flex-col md:flex-row">
					<div class="ml-8 bg-primary-light dark:bg-ternary-dark px-3 py-2 shadow-sm rounded-xl cursor-pointer">
						Tema
					<theme-switcher
						:theme="theme"
						@themeChanged="updateTheme"
					/>
				</div>
				</div>
			</li>
            </ul>
        </nav>

</template>

<script>
import ThemeSwitcher from '../ThemeSwitcher';
import feather from 'feather-icons';

export default {
	components: {
		ThemeSwitcher,
	},
	data() {
		return {
			isOpen: false,
			theme: '',
			listOne:   false,
    		listTwo:   false,
    		listThree: false,
    		listFour:  false
		};
	},
	
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	methods: {
		updateTheme(theme) {
			this.theme = theme;
		},
		myFunction() {
  			var x = document.getElementById("myTopnav");
  		if (x.className === "topnav") {
    		x.className += " responsive";
  		} else {
    		x.className = "topnav";
  			}
		}
	},
	updated() {
		feather.replace();
	},
};
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Bree+Serif);

body {
	z-index:9999;
	background: #212121;
	font-size:16px;
	line-height: 12px;
	color: #ffffff;
	margin: 0;
	padding: 0;
	word-wrap:break-word !important;
	font-family: 'Open Sans', sans-serif;
	}

h1 {
	z-index:9999;
	font-size: 20px;
	text-align: center;
	color: #FFF;
}	

h3 {
	font-size: 30px;
	line-height: 34px;
	text-align: center;
	color: #FFF;
}

h3 a {
	z-index:9999;
	color: #FFF;
}

a {
	z-index:9999;
	color: #FFF;
}

h1 {
	z-index:9999;
	margin-top: 50px;
	text-align:center;
	font-size:60px;
	line-height: 70px;
	font-family: 'Bree Serif', 'serif';
	}

#container {
	z-index:9999;
	margin: 0 auto;
	max-width: 890px;
}

p {
	text-align: center;
}

.toggle,
[id^=drop] {
	z-index:9999;
	display: none;
}

/* Giving a background-color to the nav container. */
nav { 
	z-index:9999;
	margin:10;
	padding: 10;
	background-color: #356cd1;
}

#logo {
	z-index:9999;
	display: inline-block;
	padding: 10 30px;
	float: none;
	font-size:20px;
	line-height: 20px;
}

/* Since we'll have the "ul li" "float:left"
 * we need to add a clear after the container. */

nav:after {
	z-index:9999;
	content:"";
	display:table;
	clear:both;
}

/* Removing padding, margin and "list-style" from the "ul",
 * and adding "position:reltive" */
nav ul {
	z-index:9999;
	float: left;
	padding:0;
	margin:0;
	list-style: none;
	position: relative;
	}
	
/* Positioning the navigation items inline */
nav ul li {
	z-index:9999;
	margin: 0px;
	display:inline;
	float: left;
	background-color: #4159dd;
	}

/* Styling the links */
nav a {
	z-index:9999;
	display:block;
	padding:14px 20px;	
	color:#FFF;
	font-size:24px;
	text-decoration:none;
}


nav ul li ul li:hover { 
	z-index:9999; 
background: #000000; }

/* Background color change on Hover */
nav a:hover { 
	z-index:9999;
	background-color: #000000; 
}

/* Hide Dropdowns by Default
 * and giving it a position of absolute */
nav ul ul {
	z-index:9999;
	display: none;
	position: absolute; 
	/* has to be the same number as the "line-height" of "nav a" */
	top: 60px; 
}
	
/* Display Dropdowns on Hover */
nav ul li:hover > ul {
	z-index:9999;
	display:inherit;
}
	
/* Fisrt Tier Dropdown */
nav ul ul li {
	z-index:9999;
	width:170px;
	float:none;
	display:list-item;
	position: relative;
}

/* Second, Third and more Tiers	
 * We move the 2nd and 3rd etc tier dropdowns to the left
 * by the amount of the width of the first tier.
*/
nav ul ul ul li {
	z-index:9999;
	position: relative;
	top:-60px;
	/* has to be the same number as the "width" of "nav ul ul li" */ 
	left:10px; 
}

	
/* Change ' +' in order to change the Dropdown symbol */
li > a:after { content:  ' +'; }
li > a:only-child:after { content: ''; }


/* Media Queries
--------------------------------------------- */

@media all and (max-width : 768px) {

	#logo {
		z-index:9999;
		display: inline;
		padding: 0;
		width: 25%;
		text-align: center;
		float: none;
	}

	nav {
		margin: 0;
		z-index:9999;
	}

	/* Hide the navigation menu by default */
	/* Also hide the  */
	.toggle + a,
	.menu {
		display: none;
		z-index:9999;
	}

	/* Stylinf the toggle lable */
	.toggle {
		display: block;
		z-index:9999;
		background-color: #1972d8;
		padding:14px 20px;	
		color:#FFF;
		font-size:28px;
		text-decoration:none;
		border:none;
	}

	.toggle:hover {
		background-color: #000000;
		z-index:9999;
	}

	/* Display Dropdown when clicked on Parent Lable */
	[id^=drop]:checked + ul {
		display: block;
		z-index:9999;
	}

	/* Change menu item's width to 100% */
	nav ul li {
		display: block;
		width: 100%;
		z-index:9999;
		}

	nav ul ul .toggle,
	nav ul ul a {
		padding: 0 40px;
		z-index:9999;
	}

	nav ul ul ul a {
		padding: 0 80px;
		z-index:9999;
	}

	nav a:hover,
 	nav ul ul ul a {
		background-color: #000000;
		z-index:9999;
	}
  
	nav ul li ul li .toggle,
	nav ul ul a,
  nav ul ul ul a{
		padding:14px 20px;	
		color:#FFF;
		font-size:17px;
		z-index:9999;
	}
  
  
	nav ul li ul li .toggle,
	nav ul ul a {
		background-color: #212121;
		z-index:9999;
	}

	/* Hide Dropdowns by Default */
	nav ul ul {
		float: none;
		z-index:9999;
		position:static;
		color: #ffffff;
		/* has to be the same number as the "line-height" of "nav a" */
	}
		
	/* Hide menus on hover */
	nav ul ul li:hover > ul,
	nav ul li:hover > ul {
		display: none;
		z-index:9999;
	}
		
	/* Fisrt Tier Dropdown */
	nav ul ul li {
		display: block;
		width: 100%;
		z-index:9999;
	}

	nav ul ul ul li {
		position: static;
		z-index:9999;
		/* has to be the same number as the "width" of "nav ul ul li" */ 

	}

}

@media all and (max-width : 330px) {

	nav ul li {
		display:block;
		width: 94%;
		z-index:9999;
	}

}
</style>
