<template>

	<!-- Banner start -->
  <div class="text-center"> 
      	<h3
				class="text-1xl text-blue-600 sm:text-2xl text-center sm:text-left font-semibold text-ternary-dark dark:text-primary-light uppercase"
			> Site-ul nou se afla in lucru. <br>Pentru informatii complete va rugam sa vizitati site-ul 	<a href="https://rocnee.eu" target="_blank">
				https://rocnee.eu
				</a></h3>
    </div>


	<section
		class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10"
	>
  		<div class="w-full sm:w-1/3 text-left">
		<br><br>

			<h1
				class="text-2xl text-blue-600 sm:text-5xl text-center sm:text-left font-semibold text-ternary-dark dark:text-primary-light uppercase"
			>
			<a href="https://rocnee.eu" target="_blank">
				Centrul Național de Politici și Evaluare în Educație
				</a>
			</h1>
			<p class="mt-4 text-2xml sm:text-4xl text-center sm:text-left font-semibold leading-none text-gray-400">
				<a href="https://edu.ro" target="_blank">Ministerul Educației</a>
			</p>
			<p class="mt-2 text-1xml sm:text-2xl text-center sm:text-left font-semibold leading-none text-gray-400">
				<a href="https://manuale.edu.ro" target="_blank">Manuale scolare</a>
			</p>
			<p class="mt-2 text-1xml sm:text-2xl text-center sm:text-left font-semibold leading-none text-gray-400">
				<a href="http://subiecte.edu.ro" target="_blank">Subiecte si bareme</a>
			</p>
      <p class="mt-2 text-1xml sm:text-2xl text-center sm:text-left font-semibold leading-none text-gray-400">
				<a href="https://www.ise.ro" target="_blank">Unitatea de Cercetare in Educatie</a>
			</p>
			<!-- <div class="flex justify-center sm:block">
				<a
					href="/pdf/cv.pdf"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-xl bg-indigo-50 hover:bg-indigo-500 text-gray-500 hover:text-white"
				>
								<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6"
					></i>
					<span class="text-sm sm:text-lg">Descarca ...</span>
				</a>
			</div> -->
		</div>
		<div class="w-full sm:w-2/3 text-right float-right mt-8 sm:mt-0">
			<!-- <img src="@/assets/images/cnee1.png" alt="Developer" /> -->
			<div class="slideshow-container">
    <!-- <img src="../../assets/slider/cnee1.png" style="width:100%"> -->
    <!-- <h1>Mobile Card Slider</h1> -->
    <!-- <br><br><br><br><br><br><br>
    <h1>Card Slider</h1> -->
    <div class="mySlides fade">
        <div class="numbertext">1 / 4</div>
        <img src="../../assets/slider/cnpee_nou11.png" style="width:100%" loading="lazy">
        <div class="text">Imagine 1 CNPEE</div>
    </div>

    <div class="mySlides fade">
        <div class="numbertext">2 / 4</div>
        <img src="../../assets/slider/cnpee_nou22.png" style="width:100%" loading="lazy">
        <div class="text">Imagine 2 CNPEE</div>
    </div>

    <div class="mySlides fade">
        <div class="numbertext">3 / 4</div>
        <img src="../../assets/slider/cnpee_nou33.png" style="width:100%" loading="lazy" >
        <div class="text">Imagine 3 CNPEE</div>
    </div>

    <div class="mySlides fade">
        <div class="numbertext">4 / 4</div>
        <img src="../../assets/slider/cnpee_nou44.png" style="width:100%" loading="lazy" >
        <div class="text">Imagine 4 CNPEE</div>
    </div>

    <a class="prev" @click="plusSlides(-1)">&#10094;</a>
    <a class="next" @click="plusSlides(1)">&#10095;</a>

    </div>
    <br>
    
    <div style="text-align:center">
        <span class="dot" @click="currentSlide(1)"></span> 
        <span class="dot" @click="currentSlide(2)"></span> 
        <span class="dot" @click="currentSlide(3)"></span> 
        <span class="dot" @click="currentSlide(4)"></span> 
    </div>
		</div>
		<!-- <Slider /> -->
	</section>
	<!-- Banner end -->
</template>

<script>
import feather from 'feather-icons';
//import Slider from '@/components/shared/Slider'

export default {
	// components: {
	// 	Slider,
	// },
	name: 'Home',
	props: {
		msg: String,
	},
	data: () => {
		return {
                slideIndex: 1,
                showMenu: false,
            }
	},
	mounted() {
		feather.replace();
		this.showSlides(1);
	},
	updated() {
		feather.replace();
    this.showSlides(1);
	},
	methods: {
    toggleNavbar: function(){
      this.showMenu = !this.showMenu;
    },

		plusSlides(n) {
            this.showSlides(this.slideIndex += n);
        },

        currentSlide(n) {
            this.showSlides(this.slideIndex = n);
        },

        showSlides(n) {
            var i;
            var slides = document.getElementsByClassName("mySlides");
            //console.log('slides: ',slides)
            //console.log('slides.length: ',slides.length)
            var dots = document.getElementsByClassName("dot");
            //console.log('dots: ',dots)
            if (n > slides.length) {this.slideIndex = 1}    
            if (n < 1) {this.slideIndex = slides.length}
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";  
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active", "");
            }
            slides[this.slideIndex-1].style.display = "block";  
            dots[this.slideIndex-1].className += " active";
            },
	},
};
</script>

<style scoped>

* {box-sizing: border-box}
body {font-family: Verdana, sans-serif; margin:0}
.mySlides {
  display: block;
  z-index: auto;
  }
img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  max-width: 700px;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev {
  cursor: pointer;
  position: left;
  top: 10%;
  width: auto;
  padding: 30px;
  margin-top: 22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  cursor: pointer;
  position: right;
  top: 10%;
  width: auto;
  padding: 30px;
  margin-top: 22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev, .next,.text {font-size: 11px}
}

.h1 {
  color:darkslateblue;
  
}

</style>
