<template>
    <div class="cookie" :class="containerPosition" v-if="isOpen">
        <div class="cookie__content">
            <slot name="message">
                {{message}}
            </slot>
        </div>
        <div class="cookie__button accept" @click="accept">
            {{buttonTextAccept}}
        </div>
        <div class="cookie__button deny" @click="deny">
            {{buttonTextDeny}}
        </div>
        
    </div>
</template>

<script>
    export default {
        name:"CookieMessage",
        props: {
            buttonTextAccept: {
                type: String,
                default: 'Accept'
            },
            buttonTextDeny: {
                type: String,
                default: 'Deny'
            },
            message: {
                type: String,
                default: "Utilizam cookies pentru a personaliza experienta dumneavoastră pe acest site! \
                Va rugam sa acceptati sau sa respingeti politica de utilizare a cookies.",
            },
            position: {
                type: String,
                default: 'top'
            },

        },
        data() {
            return {
                isOpen: false,

            }
        },
        computed: {
            containerPosition() {
                return `cookie--${this.position}`
            }
        },
        created() {
            if(!this.getGDPR() === true) {
                this.isOpen = true;
            }
        },
        methods: {
            getGDPR() {
                return localStorage.getItem('GDPR:accepted', true)
            },
            accept() {
                this.isOpen = false;
                localStorage.setItem('GDPR:accepted', true)
            },
            deny() {
                this.isOpen = false;
                localStorage.setItem('GDPR:accepted', false)
            },
        }
    }

</script>


<style>
.cookie {
    position: center;
    justify-items: center;
    display: inline-grid;
    
    background: #f2f2f2;
}

.cookie__content {
    grid-column: auto / span 5;
    padding: 15px;
    text-align: center;
}

.cookie__button {
    margin: 15px 0;
    grid-column: auto / span 5;
}

.cookie__button.accept {
    padding: 5px;
    background: #42B983;
    color: #ffffff;
    cursor: pointer;
}

.cookie__button.accept.hoover {
    background: #42B955;
}

.cookie__button.deny {
    padding: 5px;
    background: #cdddd6;
    color: #27801b;
    cursor: pointer;
}

.cookie--top {
    top: 0;
    left: 0;
    right: 0;
}

.cookie--bottom {
    bottom: 0;
    left: 0;
    right: 0;
}

</style>