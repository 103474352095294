import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import NProgress from 'vue-nprogress'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'C.N.P.E.E. - Home',
		},
	},
	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/About.vue'),
		meta: {
			title: 'C.N.P.E.E. - About',
		},
	},
	{
		path: '/articole',
		name: 'Articole',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Articole.vue'),
		meta: {
			title: 'C.N.P.E.E. - Articole',
		},
	},
	{
		path: '/manuale',
		name: 'Manuale',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Manuale.vue'),
		meta: {
			title: 'C.N.P.E.E. - Manuale',
		},
	},
	{
		path: '/auxiliare',
		name: 'Auxiliare',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Auxiliare.vue'),
		meta: {
			title: 'C.N.P.E.E. - Articole',
		},
	},
	{
		path: '/proiecte',
		name: 'Proiecte',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Proiecte.vue'),
		meta: {
			title: 'C.N.P.E.E. - Proiecte',
		},
	},
	{
		path: '/programe',
		name: 'Programe',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Programe.vue'),
		meta: {
			title: 'C.N.P.E.E. - Programe',
		},
	},
	{
		path: '/cpeecn',
		name: 'Cpeecn',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Cpeecn.vue'),
		meta: {
			title: 'C.N.P.E.E. - Program CPEECN',
		},
	},
	{
		path: '/examene',
		name: 'Examene',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Examene.vue'),
		meta: {
			title: 'C.N.P.E.E. - Examene',
		},
	},
	{
		path: '/projects',
		name: 'Projects',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
		meta: {
			title: 'C.N.P.E.E. - Projects',
		},
	},
	{
		path: '/toate',
		name: 'Toate',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Toate.vue'),
		meta: {
			title: 'C.N.P.E.E. - Toate',
		},
	},
	{
		path: '/manualesiauxiliare',
		name: 'Manuale si auxiliare',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Manualesiauxiliare.vue'),
		meta: {
			title: 'C.N.P.E.E. - Manuale si auxiliare',
		},
	},
	{
		path: '/dcee',
		name: 'Directia Curriculum, Evaluari si Examene',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Dcee.vue'),
		meta: {
			title: 'C.N.P.E.E. - Directia Curriculum, Evaluari si Examene',
		},
	},
	{
		path: '/declaratiiaveresiinterese',
		name: 'Declaratii si avere',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Declaratiiaveresiinterese.vue'),
		meta: {
			title: 'C.N.P.E.E. - Declaratii si avere',
		},
	},
	{
		path: '/venituriangajati',
		name: 'Venituri angajati',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Venituriangajati.vue'),
		meta: {
			title: 'C.N.P.E.E. - Venituri angajati',
		},
	},
	{
		path: '/manuale/single-project/:nr',
		name: 'Manuale Single Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/ManualeProject.vue'
			),
		meta: {
			title: 'C.N.P.E.E. - Manuale Single Project',
		},
	},
	{
		path: '/cpeecn/single-project/:nr',
		name: 'Cpeecn Single Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/CpeecnProject.vue'
			),
		meta: {
			title: 'C.N.P.E.E. - Cpeecn Single Project',
		},
	},
	{
		path: '/auxiliare/single-project/:nr',
		name: 'Auxiliare Single Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/AuxiliareProject.vue'
			),
		meta: {
			title: 'C.N.P.E.E. - Auxiliare Single Project',
		},
	},
	{
		path: '/declaratiiaveresiinterese/single-project/:nr',
		name: 'Declaratii si avere Single Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/DeclaratiiaveresiintereseProject.vue'
			),
		meta: {
			title: 'C.N.P.E.E. - Declaratii si avere Single Project',
		},
	},
	{
		path: '/venituriangajati/single-project/:nr',
		name: 'Venituri angajati Single Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/VenituriangajatiProject.vue'
			),
		meta: {
			title: 'C.N.P.E.E. - Declaratii si avere Single Project',
		},
	},
	{
		path: '/articole/single-project/:nr',
		name: 'Articole Single Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/ArticoleProject.vue'
			),
		meta: {
			title: 'C.N.P.E.E. - Articole Single Project',
		},
	},
	{
		path: '/programe/single-project/:nr',
		name: 'Programe Single Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/ProgrameProject.vue'
			),
		meta: {
			title: 'C.N.P.E.E. - Programe Single Project',
		},
	},	
	{
		path: '/examene/single-project/:nr',
		name: 'Examene Single Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/ExameneProject.vue'
			),
		meta: {
			title: 'C.N.P.E.E. - Examene Single Project',
		},
	},
	{
		path: '/proiecte/single-project/:nr',
		name: 'Proiecte Single Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/ProiecteProject.vue'
			),
		meta: {
			title: 'C.N.P.E.E. - Proiecte Single Project',
		},
	},
	{
		path: '/projects/single-project',
		name: 'Proiecte Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/SingleProject.vue'
			),
		meta: {
			title: 'C.N.P.E.E. - Single Project',
		},
	},
	//legea544
	{
		path: '/legea544',
		name: 'Legea 544',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Legea544.vue'),
		meta: {
			title: 'C.N.P.E.E. - Legea 544',
		},
	},
	
	{
		path: '/legea544/single-project/:nr',
		name: 'Legea 544 Single Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/Legea544Project.vue'
			),
		meta: {
			title: 'C.N.P.E.E. - Legea 544 Single Project',
		},
	},
	//concursuri
	{
		path: '/concursuri',
		name: 'Concursuri',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Concursuri.vue'),
		meta: {
			title: 'C.N.P.E.E. - Concursuri',
		},
	},
	
	{
		path: '/concursuri/single-project/:nr',
		name: 'Concursuri Single Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/ConcursuriProject.vue'
			),
		meta: {
			title: 'C.N.P.E.E. - Concursuri Single Project',
		},
	},
	//anunturi
	{
		path: '/anunturi',
		name: 'Anunturi',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Anunturi.vue'),
		meta: {
			title: 'C.N.P.E.E. - Anunturi',
		},
	},
	{
		path: '/anunturi/single-project/:nr',
		name: 'Anunturi Single Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/AnunturiProject.vue'
			),
		meta: {
			title: 'C.N.P.E.E. - Anunturi Single Project',
		},
	},

	{
		path: '/curriculum',
		name: 'Curriculum',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Curriculum.vue'),
		meta: {
			title: 'C.N.P.E.E. - Curriculum',
		},
	},
	{
		path: '/curriculum/single-project/:nr',
		name: 'Curriculum Single Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/CurriculumProject.vue'
			),
		meta: {
			title: 'C.N.P.E.E. - Curriculum Single Project',
		},
	},

	{
		path: '/transparenta',
		name: 'Transparenta',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Transparenta.vue'),
		meta: {
			title: 'C.N.P.E.E. - Transparenta',
		},
	},
	{
		path: '/transparenta/single-project/:nr',
		name: 'Transparenta Single Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/TransparentaProject.vue'
			),
		meta: {
			title: 'C.N.P.E.E. - Transparenta Single Project',
		},
	},
	// {
	// 	path: '/curriculum/single-project',
	// 	name: 'Curriculum Project',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (projects.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "projects" */ '../views/SingleProject.vue'
	// 		),
	// 	meta: {
	// 		title: 'C.N.P.E.E. - Single Project',
	// 	},
	// },
	{
		path: '/transparentainstitutionala',
		name: 'Transparenta institutionala',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Transparenta-institutionala.vue'),
		meta: {
			title: 'C.N.P.E.E. - Transparenta institutionala',
		},
	},
	{
		path: '/fisierefoldere',
		name: 'FisiereFoldere',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/FisiereFoldere.vue'),
		meta: {
			title: 'C.N.P.E.E. - FisiereFoldere',
		},
	},
	{
		path: '/arhiva',
		name: 'Arhiva',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Arhiva.vue'),
		meta: {
			title: 'C.N.P.E.E. - Arhiva site vechi',
		},
	},
	{
		path: '/programescolare',
		name: 'Curriculum-programescolare',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Curriculum-programescolare.vue'),
		meta: {
			title: 'C.N.P.E.E. - Curriculum-programescolare',
		},
	},
	{
		path: '/curriculum',
		name: 'Curriculum',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Curriculum.vue'),
		meta: {
			title: 'C.N.P.E.E. - Curriculum',
		},
	},
	{
		path: '/contact',
		name: 'Contact',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Contact.vue'),
		meta: {
			title: 'C.N.P.E.E. - Contact',
		},
	},
	{
		path: '/subscribe',
		name: 'Subscribe',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Subscribe.vue'),
		meta: {
			title: 'C.N.P.E.E. - Subscribe',
		},
	},
	{
		path: '/testcsrf',
		name: 'TestCsrf',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/TestCsrf.vue'),
		meta: {
			title: 'C.N.P.E.E. - TestCsrf',
		},
	},

	// {
	// 	path: '/404',
	// 	name: 'PageNotFound',
	// 	//component: PageNotFound
	// 	component: () => import('../views/PageNotFound.vue'),
	// },
	{
		path: "/:pathMatch(.*)*", // Unrecognized path automatically matches 404
    	// redirect: '/404',
		name: 'PageNotFound',
		//component: PageNotFound
		component: () => import(/* webpackChunkName: "projects" */  '../views/PageNotFound.vue'),
	  },
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;

/**
 * Below code will display the component/active page title
 * Powered by:  C.N.P.E.E.
 */

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
	// This goes through the matched routes from last to first, finding the closest route with a title.
	// e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
	// `/nested`'s will be chosen.
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) {
		document.title = nearestWithTitle.meta.title;
	} else if (previousNearestWithMeta) {
		document.title = previousNearestWithMeta.meta.title;
	}

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(
		document.querySelectorAll('[data-vue-router-controlled]')
	).map((el) => el.parentNode.removeChild(el));

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) return next();

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		.map((tagDef) => {
			const tag = document.createElement('meta');

			Object.keys(tagDef).forEach((key) => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create so we don't interfere with other ones.
			tag.setAttribute('data-vue-router-controlled', '');

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach((tag) => document.head.appendChild(tag));

	next();
});

router.beforeResolve((to, from, next) => {
	// If this isn't an initial page load.
	if (to.name) {
	  // Start the route progress bar.
	  NProgress.start()
	}
	next()
  });
  
//   router.afterEach((to, from) => {
// 	// Complete the animation of the route progress bar.
// 	NProgress.done()
//   });