<template>
  <!-- Projects start -->
  <!-- <div id="app">
    <p
      class="text-md sm:text-xl text-blue-500 dark:text-ternary-light"
      v-show="search"
    >
      Articole gasite: {{ filteredList.length }}
    </p>
    <ul>
      <li v-for="(item, index) in paginatedfilteredList" :key="index">
        {{ item.titlu }}
      </li>
    </ul>
    <button
      @click="loadMore"
      v-if="currentPage * maxPerPage < filteredList.length"
    >
      load more
    </button>
  </div> -->

  <section class="pt-20 sm:pt-28">
    <div class="text-center">
      <p
        class="
          text-2xl
          sm:text-5xl
          font-semibold
          mb-3
          text-ternary-dark
          dark:text-ternary-light
        "
      >
        Articole - total: {{ count }}
      </p>
      <p class="text-md sm:text-xl text-gray-500 dark:text-ternary-light">
        Toate articolele sunt postate in ordine cronologica
      </p>

      <br />
      <p class="text-md sm:text-xl text-blue-500 dark:text-ternary-light">
        Cauta articol dupa titlu:
      </p>
      <input
        type="text"
        name="cautare"
        @onkeyup="resultQuery"
        v-model="search"
      />
      <div class="row profile" v-for="art in filteredList" :key="art.nr">
        <!-- <div class="col-md-offset-1 col-md-3"><img :src="art.profile_pic" class="profile-pic" /></div> -->
        <!-- <div v-if="search" class="col-md-8"><h4 class="name">{{art.titlu}}</h4></div> -->
      </div>
      <!-- <div v-if="filteredList.length"> Articole gasite: {{art.length}} </div> -->
      <p
        class="text-md sm:text-xl text-blue-500 dark:text-ternary-light"
        v-show="search"
      >
        Articole gasite: {{ filteredList.length }}
      </p>
	  <button v-show="search" @click='reseteaza'
				class=" items-center px-6 py-3 rounded-xl shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 text-white text-lg sm:text-xl font-medium"
			>
				<i data-feather="refresh-cw" class="w-5 mr-3"></i>
				<span>Reseteaza...</span>
			</button>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-3 mt-16 sm:gap-10">
      <router-link
        v-for="item in paginatedfilteredList"
        :key="item.nr"
        :to="item.to + item.nr"
        :class="item.class"
      >
        <div>
          <!-- <img src="../../assets/images/manuale/toate250.png" alt="item.alt" class="item.class1" /> -->
          <img
            :src="item.src"
            :alt="item.alt"
            class="rounded-t-3xl border-none"
            loading="lazy"
          />
        </div>
        <div class="text-center px-4 py-6">
          <p
            class="
              text-2xl text-ternary-dark
              dark:text-ternary-light
              font-semibold
              mb-2
            "
          >
            {{ item.titlu }}
          </p>
          <!-- <span
						class="text-lg text-ternary-dark dark:text-ternary-light"
						> {{item.continut.slice(0, 73)}} ...</span
					> -->
        </div>

        <div class="text-center px-4 py-4">Data: {{ item.data }}</div>
      </router-link>
    </div>

    <!-- <div class="mt-10 sm:mt-20 flex justify-center">
			<button
				class="flex items-center px-6 py-3 rounded-xl shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 text-white text-lg sm:text-xl font-medium"
			>
				<i data-feather="refresh-cw" class="w-5 mr-3"></i>
				<span>Incarca mai multe...</span>
			</button>
		</div> -->
  </section>
  <br />
  <button
    class="
      space-y-233
      filteredList
      px-4
      py-2.5
      text-white
      font-medium
      tracking-wider
      bg-indigo-500
      hover:bg-indigo-600
      rounded-lg
    "
    type="submit"
    @click="loadMore"
    v-if="currentPage * maxPerPage < filteredList.length"
  >
    Incarca mai multe articole...
  </button>
  <!-- Projects end -->
</template>

<script>
import feather from "feather-icons";
//import articole from 'articole.json'
import artjson from '../../components/baza/json/articole.json'
import manjson from '../../components/baza/json/manuale.json'
import auxjson from '../../components/baza/json/auxiliare.json'
import proijson from '../../components/baza/json/proiecte.json'
import progjson from '../../components/baza/json/programe.json'
import exajson from '../../components/baza/json/examene.json'
import decljson from '../../components/baza/json/declaratiiaveresiinterese.json'
import venitjson from '../../components/baza/json/venituriangajati.json'
import currjson from '../../components/baza/json/curriculum.json'
import legea544json from '../../components/baza/json/legea544.json'
import concjson from '../../components/baza/json/concursuri.json'
import transjson from '../../components/baza/json/transparenta.json'
//import Pagination from "../../components/shared/Pagination.vue";

export default {
  name: "ToateGrid",
  //   components: {
  //     Pagination,
  //   },
  props: {
    msg: String,
  },
  data() {
    return {
      currentPage: 1,
      maxPerPage: 9,
      showReadMore: true,
      articole: [
        ...transjson, ...concjson, ...legea544json, ...venitjson, ...decljson, ...progjson, ...artjson, ...manjson, ...auxjson, ...proijson, ...exajson, ...currjson
      ],
      /* eslint-disable no-mixed-spaces-and-tabs */
      count: 0,
      search: "",
    };
  },
  computed: {
    resultQuery() {
      console.log("search: ", this.search);
      //console.log('articole_inainte_resetare: ',this.articole)
      if (this.search) {
        // return this.articole.filter((item)=>{
        // 	return this.search.toLowerCase().split(' ').every(v => item.titlu.toLowerCase().includes(v))
        // })
        
        return this.articole.filter(() =>
          this.articole.titlu.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        //this.numarpagini = Math.round(this.articole.length / 10);
        return this.articole;
      }
    },

    filteredList() {
		if (this.search != null ||  this.search != undefined ||  this.search != '') {
      //console.log('articole_inainte_resetare: ',this.articole)
			return this.articole.filter((post) => {
        		return post.titlu.toLowerCase().includes(this.search.toLowerCase());
      });
		}
      
    },

    // totalResults() {
    //   return Object.keys(this.filteredList).length;
    // },
    // pageCount() {
    //   return Math.ceil(this.totalResults / this.maxPerPage);
    // },
    // pageOffest() {
    //   return this.maxPerPage * this.currentPage;
    // },
    paginatedfilteredList() {
      return this.filteredList.slice(0, this.currentPage * this.maxPerPage);
    },
  },

  methods: {
	  reseteaza() {
			// this.search = null
			// return this.articole.filter(post => {
        	// 	return post.titlu.toLowerCase().includes(this.search.toLowerCase())
				
      		// })	
			//this.$forceUpdate();
			//this.search = null
			//return this.articole = [...concjson, ...legea544json, ...venitjson, ...decljson, ...progjson, ...artjson, ...manjson, ...auxjson, ...proijson, ...exajson, ...currjson].reverse()
			//this.$router.go('/')
      this.search = ''
			this.articole = [...transjson, ...concjson, ...legea544json, ...venitjson, ...decljson, ...progjson, ...artjson, ...manjson, ...auxjson, ...proijson, ...exajson, ...currjson];
      //console.log('articole_dupa_resetare: ',this.articole)
      //return this.articole
      //return this.articole
      return this.articole.sort(function (a, b) {
            return b.nr - a.nr;
          })
		},

    loadMore() {
      this.currentPage += 1;
    },

    // onPageChange(page) {
    //   console.log(page);
    //   this.currentPage = page;
    // },
    /* eslint-disable no-mixed-spaces-and-tabs */
    // sortArt() {
    // 	manjson.sort( function (a, b) {
    // 	 	return a.nr - b.nr;
    // 	});
    // 	console.log(manjson)
    // };
    /* eslint-disable no-mixed-spaces-and-tabs */
    nrarticole() {
      // `this` will refer to the component instance
      this.count = this.articole.length;
      //   this.numarpagini = Math.round(this.articole.length / 10);
      //console.log("numar pagini: ", this.numarpagini);
    },

    // resultQuery() {
    // 	console.log('search: ',this.search)
    // 	if(this.search){
    // 		// return this.articole.filter((item)=>{
    // 		// 	return this.search.toLowerCase().split(' ').every(v => item.titlu.toLowerCase().includes(v))
    // 		// })
    // 		return this.articole.filter( (item) => this.articole.toLowerCase().includes(this.search))
    // 	} else {
    // 	return this.articole;
    // }
    // },

    sortArticole() {
      if (this.search != null ||  this.search != undefined ||  this.search != '') {
        this.articole.sort(function (a, b) {
          return b.nr - a.nr;
        });
      } else {
		//this.articole = [...concjson, ...legea544json, ...venitjson, ...decljson, ...progjson, ...artjson, ...manjson, ...auxjson, ...proijson, ...exajson, ...currjson]
        return this.articole.titlu.toLowerCase().includes(this.search);
        //return this.articole.filter( function(art) {
        //return art.titlu.toLowerCase().includes(this.search)
        //})
      }
    },
  },
  //return articole,
  mounted() {
    feather.replace();
    this.nrarticole();
    this.sortArticole();
  },
  updated() {
    feather.replace();
  },
};
</script>


<style scoped>
.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}

.active {
  background-color: #4aae9b;
  color: #ffffff;
}
</style>