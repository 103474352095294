import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/tailwind.css';
import './assets/css/app.css';
//import VueAnalytics from 'vue-analytics';
import NProgress from 'vue-nprogress'

const feather = require('feather-icons');
feather.replace();

createApp(App)
	.use(router)
	.mount('#app');

// App.use(VueAnalytics, {
// 	id: 'G-M0RVDC6JW9',
// 	disabled: true,
// 	debug: {
// 		enabled: true,
// 		trace: true,
// 		sendHitTask: true
// 	}
// })

// App.use(NProgress)
// App.use(VueAnalytics)

const appTheme = localStorage.getItem('theme');

if (
	appTheme === 'dark' &&
	document.querySelector('body').classList.contains('app-theme')
) {
	document.querySelector('body').classList.add('bg-primary-dark');
} else {
	document.querySelector('body').classList.add('bg-secondary-light');
}
