<template>
	<div class="container mx-auto">
		<!-- Banner start -->
		<Banner />
		<!-- Banner snd -->

		<!-- Projects start -->
		<ToateGrid />
		<!-- Projects end <CookieMessage /> -->

	</div>
</template>

<script>
import Banner from '@/components/home/Banner';
import ToateGrid from '@/components/project/ToateGrid';
import feather from 'feather-icons';
//import CookieMessage from '@components/shared/CookieMessage'
//import CookieMessage from '../components/shared/CookieMessage.vue';

export default {
	name: 'Home',
	components: {
		Banner,
		ToateGrid,
	},
	props: {
		msg: String
	},
	data: () => {
		return {};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<style scoped></style>
